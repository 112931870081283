import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchGenres } from "../../../../redux-store/Actions/genre-action";
import { useDispatch, useSelector } from "react-redux";
import {
  createStoriesApi,
  createStoriesViaRSSApi,
  fetchSeasonsDetailsApi,
  createStoriesSeasonsApi,
} from "../../../../api/publisher";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ArrowIcon } from "../../../../assets/img/btn_arrow.svg";

const schema = yup
  .object({
    title: yup
      .string()
      .required()
      .min(3)
      .max(255)
      .label("Please enter a title"),
  })
  .required();

export default function CreateStory() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));

  let channelId = user.channel.id;
  let channelName = user.channel.channelName;

  const { genres } = useSelector((state) => state.genres);
  const {
    register,
    handleSubmit,
    handleInputChange,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loadedFile, setLoadedFile] = useState([]);
  const [publicationFile, setPublicationFile] = useState({});
  const [seasonCover, setSeasonCover] = useState(null);
  const [existingSeasons, setExistingSeasons] = useState([]);
  const [publishDate, setPublishDate] = useState(new Date());
  const [nextEpisodeNumber, setNextEpisodeNumber] = useState(1);
  const [contentStatus, setContentStatus] = useState("Existing");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [fileType, setFileType] = useState("");
  const [showAudioForm, setShowAudioForm] = useState(false);
  const [urlInputDisabled, setUrlInputDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState(false);
  const countries = localStorage.getItem("RIFALYCOUNTRIES")
    ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
    : [];

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  useEffect(() => {
    if (contentStatus === "Existing") {
      getSeasonDetails(channelId);
    }
  }, [contentStatus]);

  const handleContentStatusChange = (value) => {
    setContentStatus(value);
    if (value === "New") {
      setNextEpisodeNumber(1);
      setSelectedSeason(null);
    }
  };

  const getSeasonDetails = (channelId) => {
    if (!channelId) return;

    fetchSeasonsDetailsApi(channelId)
      .then((response) => {
        if (response?.data?.data) {
          const newSeasons = response.data.data;
          setExistingSeasons(newSeasons);

          if (contentStatus === "New") {
            setSelectedSeason(null);
            setNextEpisodeNumber(1);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching season details:", error);
        toast.error("Failed to fetch season details");
        setExistingSeasons([]);
      });
  };

  const getMaxSeasonId = () => {
    if (existingSeasons.length === 0) return 1;
    const maxId = Math.max(...existingSeasons.map((season) => season.id));
    return maxId + 1;
  };

  const handleSubmitRssFeed = (event) => {
    event.preventDefault();
    const urlInput = document.getElementById("RSSfeedurl").value.trim();
    if (!validateUrl(urlInput)) {
      setError(true);
      return;
    }
    handleRssFeedUrlChange();
    document.getElementById("RSSfeedurl").value = "";
  };

  const validateUrl = (url) => {
    const pattern = /^https?:\/\/.+$/;
    return pattern.test(url);
  };

  const handleTextInputClick = (event) => {
    event.stopPropagation();
  };

  const handleRssFeedUrlChange = () => {
    const urlInputValue = document.getElementById("RSSfeedurl").value.trim();
    const podcaster = {
      channelId: channelId,
      rssUrl: urlInputValue,
    };

    setLoading(true);
    createStoriesViaRSSApi(podcaster)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Story Created successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/publications/stories");
          }, 3000);
        } else {
          toast.error("Failed to create story");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred while creating the story");
        console.error(error);
      });
    setError(false);
  };

  const onDropSeasonCover = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;

      setSeasonCover({
        file: file,
        url: dataUrl,
      });
    };
    reader.readAsDataURL(file);
  };

  const files = loadedFile.map((f) => (
    <li className="file-preview-item" key={f.name}>
      <div>
        <audio src={publicationFile.url} controls>
          {f.name}
        </audio>
      </div>
    </li>
  ));

  const onDropPublicationFile = useCallback((uploadedFiles) => {
    const file = uploadedFiles[0];

    if (file.size > 100 * 1024 * 1024) {
      toast.error("File too large! Please select a file smaller than 90MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    readFiles(uploadedFiles, 1);
    setFileType(file.type);

    if (file.type.startsWith("audio/")) {
      setShowAudioForm(true);
      setUrlInputDisabled(true);
      setIsCheckboxChecked(false);
    } else {
      setShowAudioForm(false);
      setUrlInputDisabled(false);
    }
    setError(false);
    document.getElementById("RSSfeedurl").value = "";
  }, []);

  function readFiles(files, type) {
    setLoadedFile(files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;

        switch (type) {
          case 1:
            setPublicationFile({
              url: dataUrl,
            });
            break;

          default:
            break;
        }
      };
      // Read the file as a data url
      reader.readAsDataURL(file);
    });
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderAdditionalForm = () => {
    if (selectedOption === "season") {
      return (
        <div className="row g-3 mb-3">
          <div className="col-12 col-md-4 mb-1">
            <label htmlFor="contentstatus" className="form-label">
              Existing/New
            </label>
            <select
              className="form-select"
              defaultValue="Existing"
              onChange={(e) => handleContentStatusChange(e.target.value)}
            >
              <option value="Existing">Existing</option>
              <option value="New">New</option>
            </select>
          </div>

          {contentStatus === "Existing" ? (
            <div className="col-12 col-md-4 mb-1">
              <label htmlFor="Season" className="form-label">
                Season
              </label>
              <select
                className="form-select"
                {...register("season")}
                value={selectedSeason || ""}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  setSelectedSeason(selectedId);
                  const episode = existingSeasons.find(
                    (s) => parseInt(s.id) === parseInt(selectedId)
                  );
                  setNextEpisodeNumber(
                    episode ? episode.numberOfEpisodes + 1 : 1
                  );
                }}
              >
                <option value="" disabled>
                  Select season number
                </option>
                {existingSeasons.map((season) => (
                  <option key={season.id} value={season.id}>
                    {season.title}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="col-12 col-md-4 mb-1">
              <label htmlFor="Season" className="form-label">
                Season
              </label>
              <select
                className="form-select"
                {...register("season")}
                value={getMaxSeasonId()}
                disabled
              >
                <option value={getMaxSeasonId()}>{getMaxSeasonId()}</option>
              </select>
            </div>
          )}

          <div className="col-12 col-md-4 mb-1">
            <label htmlFor="Episode" className="form-label">
              Episode
            </label>
            <input
              type="text"
              className="form-control"
              value={nextEpisodeNumber}
              disabled
              {...register("episode")}
            />
          </div>
          <div className="col-12 col-md-12 mb-1">
            <label htmlFor="price" className="form-label">
              Description
            </label>
            <textarea
              type="number"
              {...register("description")}
              className="form-control"
              placeholder="Description"
            ></textarea>
          </div>
          <div className="col-md-4">
            <Dropzone
              onDrop={onDropSeasonCover}
              multiple={false}
              accept="image/*"
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Cover Art</label>
                  </div>
                  <div
                    {...getRootProps({
                      className: "dropzone dropzone-single mb-3",
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload</p>
                      <div className="d-flex justify-content-between RssFeedDrop">
                        <div
                          className="btn btn-sm btn-outline-primary"
                          style={{ borderRadius: "30px", minWidth: "270px" }}
                        >
                          Browse from your files
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-md-6">
            {seasonCover && seasonCover.url ? (
              <aside className="file-preview">
                {seasonCover && (
                  <div>
                    <img
                      className="viewer-mini"
                      src={seasonCover.url}
                      alt={seasonCover.file.name}
                      style={{ transform: "scale(0.80)" }}
                    />
                  </div>
                )}
              </aside>
            ) : null}
          </div>
        </div>
      );
    } else if (selectedOption === "episodes") {
      return (
        <div>
          <div className="col-12 col-md-12 mb-1">
            <label htmlFor="price" className="form-label">
              Description
            </label>
            <textarea
              type="number"
              {...register("description")}
              className="form-control"
              placeholder="Description"
            ></textarea>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    if (!publicationFile.url) {
      toast.error("Please select a valid audio file", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } else {
      data.audioFile = publicationFile;
      data.fileInfo = {
        lastModified: loadedFile[0].lastModified,
        lastModifiedDate: loadedFile[0].lastModifiedDate,
        name: loadedFile[0].name,
        size: loadedFile[0].size,
        type: loadedFile[0].type,
      };
      data.releaseDate = publishDate;
      data.channelId = channelId;
      data.episode = nextEpisodeNumber;

      if (seasonCover) {
        data.seasonCover = seasonCover;
      }

      createStoriesSeasonsApi(data)
        .then((res) => {
          setLoading(false);
          e.target.reset();

          toast.success("Story Created successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/publications/stories");
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }
  useEffect(() => {
    dispatch(fetchGenres());
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/publications" onClick={toPage("/publications")}>
                      Publications
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a
                      href="/publications/stories"
                      onClick={toPage("/publications/stories")}
                    >
                      Stories
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload Story
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card pt-3">
                  <div className="px-4 pt-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Upload Story</h1>
                      </div>

                      <div className="col-auto"></div>
                    </div>

                    <hr />
                  </div>

                  <div className="card-body" id="dropzone">
                    <div className="row">
                      {loading ? (
                        <span className="container d-flex justify-content-center">
                          <Loader
                            type="TailSpin"
                            color="#2bc38c"
                            height={80}
                            width={80}
                          />
                        </span>
                      ) : (
                        <div className="col-md-12">
                          <Dropzone
                            onDrop={onDropPublicationFile}
                            multiple={false}
                            accept="audio/*"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="mb-1">
                                  <label className="form-label">Upload</label>
                                </div>
                                <div
                                  {...getRootProps({
                                    className: "dropzone dropzone-single mb-3",
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <div className="upload-content p-4">
                                    <i className="fe fe-upload-cloud"></i>
                                    <p>Drag and drop or click to upload</p>
                                    <div className="d-flex justify-content-between RssFeedDrop">
                                      <div
                                        className="btn btn-sm btn-primary"
                                        style={{
                                          borderRadius: "30px",
                                          minWidth: "270px",
                                        }}
                                      >
                                        Browse from your files
                                      </div>
                                      <div className="RSSor"> OR </div>
                                      <div
                                        className="urlinput"
                                        onClick={handleTextInputClick}
                                      >
                                        <input
                                          type="url"
                                          name="websiteUrl"
                                          pattern="https?://.+"
                                          placeholder="Enter your URL..."
                                          className="RSSfeedurl"
                                          id="RSSfeedurl"
                                          disabled={urlInputDisabled}
                                        />
                                        <button
                                          type="submit"
                                          onClick={handleSubmitRssFeed}
                                          className="urlinput_BTN"
                                          disabled={!isCheckboxChecked}
                                        >
                                          {loading ? (
                                            <Loader
                                              type="TailSpin"
                                              color="#ffffff"
                                              height={20}
                                              width={20}
                                            />
                                          ) : (
                                            <ArrowIcon />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="checkbox-container d-flex flex-column align-items-center">
                                    <div className="mb-2">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        onClick={handleTextInputClick}
                                        onChange={handleCheckboxChange}
                                        checked={isCheckboxChecked}
                                      />
                                      <label
                                        htmlFor="checkbox"
                                        style={{ paddingLeft: "8px" }}
                                      >
                                        I agree to &nbsp;
                                        <a
                                          href="https://www.rifaly.com/podcasters-terms"
                                          onClick={handleTextInputClick}
                                        >
                                          Rifaly Terms of Services
                                        </a>
                                        .
                                      </label>
                                    </div>
                                    {!isCheckboxChecked && (
                                      <p className="error-message">
                                        Please click the checkbox to send the
                                        URL.
                                      </p>
                                    )}
                                    {error && (
                                      <p className="error-message">
                                        Invalid URL. Please enter a valid URL.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>

                          <aside>
                            <ul className="file-preview">{files}</ul>
                          </aside>
                        </div>
                      )}
                    </div>
                  </div>

                  {fileType.startsWith("audio/") && showAudioForm && (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="p-3"
                      id="audioForm"
                    >
                      <div className="row g-3 mb-3">
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="audio" className="form-label">
                            Publisher
                          </label>
                          <select
                            defaultValue={channelId}
                            className="form-select"
                          >
                            {
                              <option key={channelId} value={channelId}>
                                {channelName}{" "}
                              </option>
                            }
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationServer02"
                            placeholder="Enter Story Title"
                            required
                            {...register("title")}
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="category" className="form-label">
                            Genre
                          </label>
                          <select
                            {...register("categoryId")}
                            onChange={handleInputChange}
                            className="form-select"
                          >
                            <option disabled>Select Story Genre</option>
                            {genres.map((g) => {
                              return (
                                <option key={g.id} value={g.id}>
                                  {g.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="language" className="form-label">
                            Publication Date and Time
                          </label>
                          <br />
                          <DatePicker
                            className="form-control"
                            showTimeSelect
                            selected={publishDate}
                            minDate={new Date().getTime}
                            timeIntervals={60}
                            dateFormat="dd-MMM-yyyy H:mm"
                            onChange={(date) => setPublishDate(date)}
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="category" className="form-label">
                            Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="0"
                            {...register("price")}
                          />
                        </div>
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="category" className="form-label">
                            Currency
                          </label>
                          <select
                            defaultValue={"TZS"}
                            {...register("currency")}
                            className="form-control"
                          >
                            {countries.map((c) => {
                              return (
                                <option key={c.countryCode} value={c.currency}>
                                  {c.currency}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="language" className="form-label">
                            Language
                          </label>
                          <select
                            className="form-select"
                            {...register("language")}
                            defaultValue={"EN"}
                          >
                            <option disabled>Select Language</option>
                            <option value="EN">English</option>
                            <option value="SW">Swahili</option>
                          </select>
                        </div>
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="author" className="form-label">
                            Author
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={channelName}
                            {...register("author")}
                          />
                        </div>
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="audio" className="form-label">
                            Show Type
                          </label>
                          <select
                            className="form-select"
                            defaultValue=""
                            onChange={handleOptionChange}
                          >
                            <option disabled="">Select Type</option>
                            <option value="season">Season</option>
                            <option value="episodes">Episodes</option>
                          </select>
                        </div>
                        <div>{renderAdditionalForm()}</div>
                      </div>

                      <div className="row mt-3">
                        <div className="col"></div>
                        <div className="col-auto">
                          <div className="d-flex">
                            <button
                              type="reset"
                              className="btn btn-outline-secondary acha-right"
                            >
                              Cancel
                            </button>

                            {loading ? (
                              <span className="btn-primary">
                                <Loader
                                  type="TailSpin"
                                  color="#ffffff"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            ) : (
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
